<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-6 col-12">Plan Change Request</div>
    <div
      class="
        col-lg-6 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      "
    >
      <button
        type="button"
        class="btn custom-view-detail-btn"
        @click="backtodashboard()"
      >
        Back To Dashboard
      </button>
    </div>
  </div>
  <div class="custom-ultima-datatable">
    <DataTable
      :value="requestChangePlanList"
      :scrollable="true"
      scrollHeight="flex"
      :paginator="true"
      :rows="30"
      :lazy="true"
      :rowHover="true"
      :totalRecords="totalRecords"
      @page="onPage($event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      responsiveLayout="scroll"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      :loading="loading"
    >
    <template v-if="!loading" #empty>No records found.</template>
    <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
      <Column
        field="planname"
        header="Plan Name"
        headerStyle="width: 30%"
        bodyStyle="width: 30%"
      >
        <template #body="{ }">
            <div class="label-heading text-capitalize">
              Premium Plan - Base
            </div>
        </template>
      </Column>
      <Column
        field="requestdate"
        header="Request Date"
        headerStyle="width: 13%"
        bodyStyle="width: 13%"
      >
        <template #body="{ }">
          <div class="label-subheading">
            20 Feb 2022
          </div>
        </template>
      </Column>
      <Column
        field="addedby"
        header="Added By"
        headerStyle="width: 15%"
        bodyStyle="width: 15%"
      >
        <template #body="{ }">
            <div class="label-subheading text-capitalize">
              Mr. Tushar Salunkhe
            </div>
        </template>
      </Column>
      <Column
        field="remark"
        header="Cancelled Remark"
        headerStyle="width: 29%"
        bodyStyle="width: 29%"
      >
        <template #body="{  }">
          <div class="label-subheading">
            Due to unsufficient balance
          </div>
        </template>
      </Column>
      <Column
        field="status"
        header="Status"
        headerStyle="width: 13%"
        bodyStyle="width: 13%"
      >
        <template #body="{ }">
            <div>
            <div class="label-subheading">
                <!-- <span
                class="appointment-badge status-cancelled"
                >Rejected</span> -->
              <span
                class="appointment-badge status-scheduled"
                >Approved</span
              >
            </div>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>
<script>
import ApiService from "../../service/ApiService";
export default {
  data() {
    return {
      requestChangePlanList: null,
      totalRecords: 0,
      loading: false,
    };
  },

  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },

  mounted() {
    this.loading = true;
    this.getcustomers();
  },
  methods: {
    getcustomers(e) {
      this.loading = true;
      this.ApiService.getcustomers(e).then((data) => {
        if (data.status == 200) {
          this.requestChangePlanList = data.data.result;
          this.totalRecords = data.data.count;
          this.loading = false;
        } else {
          this.loading = false;
          this.requestChangePlanList = null;
        }
      });
    },
    onPage(event) {
      this.loading = true;
      this.page_no = event.page;
      this.getcustomers({
        page_no: this.page_no,
      });
    },
    backtodashboard() {
      this.$router.push("/billing/dashboard");
    },
  },
};
</script>
<style scoped>
</style>